import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/SEO';
import Theme from '../../src/components/Theme';
import BlobsBackground from '../../src/components/Blobs/Background';
import GeneralLayout from '../layouts/index';
import * as styles from '../../src/components/404/404.module.scss';

const NotFoundPage = ({ transition, data }) => {
  const { site } = data;

  return (
    <GeneralLayout>
      <Theme transition={transition}>
        <Seo siteData={site} defaultTitle="Emergence - Not Found" pathName="/404" />
        <div className={styles.content}>
          <div className={styles.inner}>
            <div className={styles.cardWrapper}>
              <div className={styles.card}>
                <div className={styles.tags}>
                  <div className={styles.tagText}>404</div>
                </div>
                <h1>Sorry—we couldn’t find that page.</h1>
                <p>
                  Try the menu above or return to the{' '}
                  <a className={styles.redirectLink} href="/">
                    homepage
                  </a>
                  .
                </p>
              </div>
            </div>
            <BlobsBackground />
          </div>
        </div>
      </Theme>
    </GeneralLayout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery {
    ...SiteQuery
  }
`;
